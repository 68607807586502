.search-box {
  padding: 0px 0px 5px 17px;
  /* background-color: #eee; */
}
/* .search-box input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
} */

.dropdown {
  width: 50%;
}

.issue-button {
  margin-top: 17px;
  display: block;
}

/*.issue-dropdown-container {*/
/*    margin-bottom: 10px;*/
/*    display: block;*/
/*}*/
