#renderer-print-version {
  visibility: hidden;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  max-height: 1000px;
  overflow: visible;
  width: 100%;
  max-width: 650px;
  z-index: 99999 !important;
  page-break-after: always !important;
}

@media print {
  @page {
    size: 210mm 297mm; /* portrait */
    /* you can also specify margins here: */
    /* for compatibility with both A4 and Letter */
    /*margin: 0 0 0 0;*/
  }

  body,
  html,
  onboard-v2 {
    visibility: hidden !important;
    width: 210mm !important;
    /*width: 297mm;*/
    zoom: 100%;
  }

  .noprint {
    display: none;
  }

  button {
    display: none !important;
  }

  svg {
    display: none;
  }

  #renderer-print-version {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    max-height: 1000px;
    width: 650px;
    visibility: visible;
    overflow: visible;
    z-index: 99999 !important;
    page-break-after: always !important;
    transform: scale(1.5);
    transform-origin: top left;
    -moz-transform: scale(1);
  }
}

/*@media print and (-webkit-min-device-pixel-ratio:0) {*/
/*    #renderer-print-version {*/
/*        transform: scale(1.5);*/
/*        transform-origin: top left;*/
/*    }*/
/*}*/
